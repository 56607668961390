<template>
  
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
    <b-row class="match-height">
    <b-col
      md="4"
      class="my-1"
      v-for="bookedEvent in bookedEventLists"
      :key = "bookedEvent.id"
    >
    <b-card
    no-body
    class="card-developer-meetup"
  >
    <div class="bg-light-primary rounded-top text-center">
      <b-img
        :src="require('@/assets/images/illustration/email.svg')"
        alt="Meeting Pic"
        height="170"
      />
    </div>
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <h6 class="mb-0">
            
            {{moment(bookedEvent.event.start_date).format('ddd')}}
          </h6>
          <h3 class="mb-0">
            {{moment(bookedEvent.event.start_date).format('DD')}}
          </h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{bookedEvent.event.title}}
          </b-card-title>
          <!--<b-card-text class="mb-0">
            Meet world popular developers
          </b-card-text>-->
        </div>
      </div>
      <b-card-text class="mb-2">
        {{bookedEvent.event.description}}
      </b-card-text>
      <!--/ metting header -->

      <!-- media -->
      <b-media
        no-body
        class="mb-2"
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{moment(bookedEvent.event.start_date).format('ddd, MMMM DD, YYYY')}}
          </h6>
          <small>{{moment(bookedEvent.event.start_date).format('hh:mm:a')}} to {{moment(bookedEvent.event.end_date).format('hh:mm:a')}}</small>
        </b-media-body>
      </b-media>
      <b-media
        no-body
        class="mb-2"
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="MapPinIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{bookedEvent.event.location}}
          </h6>
          <small></small>
        </b-media-body>
      </b-media>
      <b-media
        no-body
        class="mb-2"
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="user"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{bookedEvent.event.available_slot}}
          </h6>
          <small>Available Slots</small>
        </b-media-body>
      </b-media>
      <!-- avatar group -->
      <b-button
        v-if="bookedEvent.event.time_diff"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="danger"
        @click="cancelEvent(bookedEvent.event.id)"
      >
        Cancelar Practica
      </b-button>

    </b-card-body>
  </b-card>
    </b-col>
    </b-row>
    <!-- pagination -->

    <b-col>
      
      
    <record-count :to ='toRecordCount' :from='fromRecordCount' :total='totalRecordCount'></record-count>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :base-url="path"
        :per-page="perPageRecord"
        :align="selected"
        @input = "changePage(currentPage)"
        class = "mb-4"
      />
    </b-col>
      
    </b-overlay>
 
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable,BCard, BImg, BCardBody, BCardText, BCardTitle, BProgress,BButton, BMedia, BMediaAside, BAvatar, BAvatarGroup, BMediaBody,BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BRow , BCol, BLink, BIcon} from 'bootstrap-vue'
import EventService  from '@/services/event.service.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BLink,
    BIcon,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BCard, 
    BImg,
    BCardBody, 
    BCardText, 
    BCardTitle
  },
  directives: {
    Ripple,
    moment
  },
  data() {
    return {
      bookedEventLists: [],
      marginePage: 10,
      initialPage: 0,
      pageRange: 10,
      pageCount: 0,
      currentPage: 0,
      srCounter: 0,
      perPageRecord: 0,        
      toRecordCount: 0,
      fromRecordCount: 0,
      totalRecordCount: 0,
      selected: 'right',
      currentPage: 1,
      rows: 0,
      path: '',
      moment: moment,
      showOverlay: false,
      eventData: {
        event_id: '',
        status: 'cancelled'
      },
      mediaData: [
        { avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM' },
        { avatar: 'MapPinIcon', title: 'Central Park', subtitle: 'Manhattan, New york City' },
      ],
      avatars: [
        { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' },
      ],
    }
  },
  
  methods: {
    bookedEvents() {
        this.showOverlay = true;
        EventService.loggedInBookedEvent(
            this.currentPage,
        ).then(response => {
            this.bookedEventLists = response.data.data.events;
            
            this.showOverlay = false;
        }).catch(error => {
            this.showOverlay = false;
            console.log(error);
        });
    },
    cancelEvent(event_id)
    {
        this.eventData.event_id = event_id;
        this.showOverlay = true;
        EventService.bookOrCancelEvent(this.eventData).then(response => {
            this.showOverlay = false;
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Event Cancelled Successfully',
                    icon: 'EditIcon',
                    variant: 'danger',
                },
            })
            this.bookedEvents(this.currentPage)
        }).catch(error => {
            this.showOverlay = false;
            this.errors = error.response
        });
    },
    changePage(event) {
      this.bookedEvents(this.currentPage);
    },
    
  },
  created() {
    this.bookedEvents(this.currentPage);
  }
}
</script>
