import axiosIns from '@/libs/axios.js';

class EventService {
    getEventByCourse(pageNumber=1){
        return axiosIns.get(`/course-event/listing?page=${pageNumber}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }  
    
    bookOrCancelEvent(data)
    {
        return axiosIns.post(`book-cancel-event`, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    loggedInBookedEvent(pageNumber=1)
    {
        return axiosIns.get(`student-booked-event?page=${pageNumber}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    allBookedEvent(pageNumber=1)
    {
        return axiosIns.get(`booked-event?page=${pageNumber}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    getAll(pageNumber=1)
    {
        return axiosIns.get(`all-event/listing?page=${pageNumber}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    getUserByEvent(id)
    {
        return axiosIns.get(`find-event-student/${id}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    findEvent(id)
    {
        return axiosIns.get(`find-event/${id}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    cancelledEvent(userId, eventId)
    {
        let data = {
            event_id: eventId,
            user_id: userId
        }
        return axiosIns.post(`admin-cancelled-event`, data).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
    
}

export default new EventService();